import { createControllerList } from '@/utils/create-controllers';
import inactivityTimer from '@/utils/inactivity-timer';
import modalCreator from '@/components/modal/modal';

function backToHome() {
    window.location.href = '/';
}

export default function timeoutModalCreator(el) {
    const [modal] = createControllerList(el, modalCreator);
    const exitTimer = inactivityTimer(backToHome, 30 * 1000); // Close session after 90 sec.
    const modalTimer = inactivityTimer(() => {
        if (!modal.open) {
            modal.show();
            exitTimer.start();
        }
    }, 60 * 1000); // Render modal after 60 sec.

    modalTimer.start();

    Array.from(el.querySelectorAll('button')).forEach(button => {
        button.addEventListener('click', e => {
            exitTimer.stop();
            modalTimer.reset();
            button.dataset.answer === 'no'
                ? backToHome()
                : modal.close(e.currentTarget.dataset.answer);
        });
    });

    return {
        ...modal,
        pause() {
            modalTimer.stop();
            exitTimer.stop();
        },
        resume() {
            modalTimer.start();
        }
    }
}
