import timeOutModalCreator from '@/components/timeout-modal/timeout-modal';
import newScanModalCreator from '@/components/new-scan-modal/new-scan-modal';
import scanInterceptorCreator from '@/components/scan-interceptor/scan-interceptor';
import { createControllerList } from '@/utils/create-controllers';
import scanCode from '@/utils/scan-code';

export function init({
    errorOverlay,
    questions,
    chatWidgets
}) {
    let lastScannedCode;

    const [timeoutModal] = createControllerList(document, '.timeout-modal', timeOutModalCreator);
    const [newScanModal] = createControllerList(document, '.new-scan-modal', newScanModalCreator);
    const scanInterceptors = createControllerList(document, '[data-scan-interceptor]', scanInterceptorCreator);

    chatWidgets.forEach((chatWidget) => {
        chatWidget.addCallback((isConnected) => {
            if(isConnected) {
                timeoutModal.pause();
            } else {
                timeoutModal.resume();
            }
        });
    });

    function handleAnswer(e) {
        if (e.detail.endsFlow) {
            scanInterceptors.forEach(interceptor => interceptor.stop());
        }
    }

    questions.forEach(question => {
        question.addEventListener('answered', handleAnswer);
    });

    scanInterceptors.forEach(interceptor => {
        interceptor.addEventListener('scan', (e) => {

            /*
                Ignore the situation were you scan the same medicine twice
            */
            if(e.detail.code.toString() === window.medicineCode) {
                return;
            }

            lastScannedCode = e.detail.code;
            newScanModal.show();

            scanCode(lastScannedCode)
                .then(({ metadata }) => {
                    newScanModal.show(metadata.name);
                })
                .catch(() => {
                    scanInterceptors.forEach(interceptor => interceptor.reset());
                    newScanModal.close();
                    errorOverlay.show();
                });
        });
    });

    newScanModal.addEventListener('close', () => {
        if (newScanModal.getReturnValue() === 'no') { // the user wants to leave the session
            scanCode(lastScannedCode)
                .then(({ redirect }) => {
                    redirect();
                });
        }
    });
}
