import { createControllerList } from "@/utils/create-controllers";
import modalCreator from "@/components/modal/modal";
import template from "@/utils/template";

export default function newScanModalCreator(el) {
    const [modal] = createControllerList(el, modalCreator);

    const headerTemplate = document.querySelector('[data-header-template]');
    const header = document.querySelector('[data-header]');

    Array.from(el.querySelectorAll('button')).forEach(button => {
        button.addEventListener('click', (e) => {
            modal.close(e.currentTarget.dataset.answer);
        });
    });

    return {
        ...modal,

        show(newMedicineName) {
            header.innerHTML = template(headerTemplate, {
                ['new-medicine']: newMedicineName
            });
            modal.show();
        }
    }
}
