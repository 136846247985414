import replace from '../../lib/template-replacer';

export default function template(tag, data) {
    const scanInfoWrapper = document.createElement("span");
    scanInfoWrapper.appendChild(tag.content.cloneNode(true));

    const nodeIterator = document.createNodeIterator(
        scanInfoWrapper,
        NodeFilter.SHOW_TEXT
    );

    let currentNode;
    while ((currentNode = nodeIterator.nextNode())) {
        currentNode.textContent = replace(currentNode.textContent, data);
    }

    return scanInfoWrapper.innerHTML;
}
