export default function scanInterceptorCreator(el) {
    let stopped = false;

    function reset() {
        el.reset();
    }

    el.addEventListener('submit', (e) => {
        e.preventDefault();
        const code = el.elements.code.value;
        reset();
        if(!stopped) {
            el.dispatchEvent(new CustomEvent('scan', {
                detail: {
                    code,
                },
            }));
        }
    });

    /**
     * Make sure that focus stays inside code field
     */
    document.body.addEventListener('focusout', () => {
        const element = el.elements.code;
        element.setAttribute('readonly', 'readonly');
        setTimeout(() => {
            element.focus();
            element.removeAttribute('readonly');
        }, 100);
    });

    return {
        reset,
        stop() {
            stopped = true;
        }
    };
}
